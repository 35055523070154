import React, { Component } from 'react';
import dextools from '../../assets/img/icons/dextools.png';
import discord from '../../assets/img/icons/discord.png';
import etherscan from '../../assets/img/icons/etherscan.png';
import twitter from '../../assets/img/icons/twitter.png';
import uniswap from '../../assets/img/icons/uniswap.png';

export default class BannerContent extends Component {
	render() {
		return (
			<React.Fragment>
				<div className='banner__content--wrap'>
					<h2 className='banner--title'>
						 
						<br />
						<span>$BEEF</span> COIN
					</h2>
					<p>  Mad Pepe, going wild, heads for the Avalanche to take over, picking a $BEEF with all memecoins and shady founders. </p>
					<p>Join the movement. </p>
					<p>be there or be square ! 🥊</p>
					<p> Supply : 117B</p>
                    <p>              </p>
					
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '10px',
						}}
					>
						<a
							href='https://dexscreener.com/avalanche/0x07997a820dab78f5dfacbc23dd25fdb1a8658f23'
							target='_blank'
							rel='noreferrer'
						>
							<img
								style={{ width: '60px' }}
								src={dextools}
								alt='img'
							/>
						</a>
						<a
							href='https://discord.gg/E54wemmAcQ'
							target='_blank'
							rel='noreferrer'
						>
							<img
								style={{ width: '60px' }}
								src={discord}
								alt='img'
							/>
						</a>
						<a
							href='https://snowtrace.io/token/0xF61818a9DE8Aa8f2372ab100D525ae87B7ce6770/contract/code?chainId=43114'
							target='_blank'
							rel='noreferrer'
						>
							<img
								style={{ width: '60px' }}
								src={etherscan}
								alt='img'
							/>
						</a>
						<a
							href='https://twitter.com/beefcoinavax'
							target='_blank'
							rel='noreferrer'
						>
							<img
								style={{ width: '60px' }}
								src={twitter}
								alt='img'
							/>
						</a>
						<a
							href='https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xF61818a9DE8Aa8f2372ab100D525ae87B7ce6770'
							target='_blank'
							rel='noreferrer'
						>
							<img
								style={{ width: '60px' }}
								src={uniswap}
								alt='img'
							/>
						</a>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
