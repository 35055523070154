import React, { Component } from 'react';
import Logo from './Logo';
import Toast from 'react-bootstrap/Toast';

export default class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			account: '',
			toast: false,
		};
	}

	componentDidMount() {}

	handleToast() {
		this.setState({ toast: !this.state.toast });
	}

	render() {
		return (
			<React.Fragment>
				{/* ====== HEADER AREA START ====== */}
				<header>
					<div className='menu__area transparent__header'>
						<div className='container'>
							<div className='row'>
								<div className='col-12'>
									<div className='menu__wrap'>
										<nav className='menu__nav'>
											<Logo />
										</nav>
									</div>
									<Toast
										show={this.state.toast}
										onClose={() => this.handleToast()}
									>
										<Toast.Header>
											<img
												src='holder.js/20x20?text=%20'
												className='rounded me-2'
												alt=''
											/>
											<strong className='me-auto'>Notice</strong>
										</Toast.Header>
									</Toast>
								</div>
							</div>
						</div>
					</div>
				</header>

				{/* ====== HEADER AREA END ====== */}
			</React.Fragment>
		);
	}
}
